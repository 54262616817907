import React, { useState, useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import ManagedITServices from './Images/S/first-img.webp'
import RevenueCycleManagement from './Images/S/new/revenue-cycle-management.webp'
import EnterpriseIT from './Images/S/Enterprise-IT-S.jpg'
import ElectronicHealthRecord from './Images/S/Electronic-Health-Record-S.jpg'
import HealthcareStaffAugmentation from './Images/S/Healthcare-Staff-Augmentation-S.jpg'
import Marketing from './Images/S/marketing-s.jpg'

function SliderDetail() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false); // State to track if the image has loaded

  const slides = [
    { id: 1, image: RevenueCycleManagement, title: "Revenue Cycle Management", cardPara: "Streamline financial operations by efficiently managing the healthcare revenue cycle.", readMore: "/revenue-cycle-management" },
    { id: 2, image: ManagedITServices, title: "Workers' Compensation", cardPara: "Our solutions optimize claims processing for timely reimbursements and regulatory compliance.", readMore: "/workers-compensation" },
    { id: 3, image: EnterpriseIT, title: "Personal Injury", cardPara: "Personal injury law offers remedies for individuals harmed by others' negligence or intentional actions.", readMore: "/personal-injury" },
    { id: 4, image: ElectronicHealthRecord, title: "Medical Virtual Assistant", cardPara: "Remote professionals provide administrative support to healthcare providers.", readMore: "/virtual-medical-assistants"},
    // { id: 5, image: HealthcareStaffAugmentation, title: "Where Potential Becomes Real", cardPara: "The freedom to create your own path. The support to succeed. Discover more about our dynamic people and career paths.", readMore: "/" },
    // { id: 6, image: Marketing, title: "Marketing", cardPara: "Scale your operations efficiently with our enterprise IT services.", readMore: "/" },
  ];

  useEffect(() => {
    // Preload the first image
    const image = new Image();
    image.src = slides[0].image;
    image.onload = () => setImageLoaded(true);

    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000); // Change slide every 5 seconds (adjust as needed)

    return () => clearInterval(interval);
  }, [slides]);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="slider-container" style={{ minHeight: imageLoaded ? "auto" : "600px" }}>
      {!imageLoaded && <div className="loading-spinner">Loading...</div>} {/* Show loading spinner */}
      <div className={`slider ${imageLoaded ? "visible" : "hidden"}`}>
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img
              src={slide.image}
              alt={slide.title}
              className="sliderImage"
              onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image loads
            />
            <div className="overlay"></div> {/* Add overlay */}
          </div>
        ))}
        <div className="slider-dots">
          {slides.map((slide, index) => (
            <span
              key={slide.id}
              className={`dot ${index === currentSlide ? "active" : ""}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
        <div className="white-line"></div>
      </div>
      <div className="slider-cards">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`card ${index === currentSlide ? "active" : ""}`}
            style={{
              left: `${index * 18}%`, // Adjust the position based on the index
              display: index === currentSlide ? "block" : "none"
            }}
          >
            <div className="card-content">
              <h1>{slide.title}</h1>
              <p>{slide.cardPara}</p>
              <Link to={slide.readMore} className="cta-button" onClick={scrollToTop}>Read More</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SliderDetail;
